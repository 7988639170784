.modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 45rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 1rem 2rem 1rem;
    /* background: hsl(345deg 100% 47%); */
    background-color: #1D1E22;
    border-radius: 1rem;
    box-shadow:0 1rem 2rem rgba(0,0,0,0.5);
    z-index: 500;
}

.modal_sub_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.img_container{
    width: 20rem;
    height: 15rem;
}

.img_container img{
    width: 100%;
    height:100%;
    object-fit: contain;
}

.modal_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.modal_title{
    font-size:2.5rem;
    font-family: 'Signika Negative',sans-serif;
    font-weight: bold;
    color:#CECED3;
}

.paragraph{
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    color: #CECED3;
    font-weight: bold;
    text-align: center;
}

.buttons_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.play_again_btn{
    padding:0.5rem;
    background-color: #4741FC;
    color: white;
    font-size: 2rem;
    border-radius: 0.2rem;
    box-shadow:0 0.8rem 2rem rgba(0,0,0,0.5);
    font-family: 'Signika Negative',sans-serif;
}

.levelup_btn{
    padding: 0.5rem;
    background-color: #4741FC;
    color: white;
    font-size: 2rem;
    border-radius: 0.2rem;
    box-shadow:0 0.8rem 2rem rgba(0,0,0,0.5);
    font-family: 'Signika Negative',sans-serif;
}

.levelup2_btn{
    padding: 0.5rem;
    background-color: #4741FC;
    color: white;
    font-size: 2rem;
    border-radius: 0.2rem;
    box-shadow:0 0.8rem 2rem rgba(0,0,0,0.5);
    font-family: 'Signika Negative',sans-serif;
}

.cancel_btn{
    padding: 0.5rem;
    background-color: #303137;
    color: white;
    font-size: 2rem;
    border-radius: 0.2rem;
    box-shadow:0 0.8rem 2rem rgba(0,0,0,0.5);
    font-family: 'Signika Negative',sans-serif;
}

@media screen and (max-width:470px){
    .modal{
        width: 33rem;
    }
    .play_again_btn{
        font-size: 1.5rem;
    }
    .levelup_btn,.levelup2_btn,.cancel_btn{
        font-size: 1.5rem;
    }
}