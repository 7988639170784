.container{
    width: 100%;
    padding: 3rem 1rem 3rem 1rem;
    position: relative;
    background-color: #ecf2ff;
}

.sub_container{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90dvh;
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 0 0.3rem 3rem rgba(32, 61,127, 0.3);
    overflow: hidden;
}