.container{
    position:relative;
    width:100%;
    height:100dvh;
    background-attachment: fixed;
    background-color: #008ADD;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:1rem;
}

.back_icon_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    padding: 1rem 4rem 1rem 4rem;
    position: relative;
}

.back_icon{
    width: 4rem;
    height: 4rem;
    background-color: #1F1C3A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.3rem 2rem rgba(0,0,0,0.4);
    cursor: pointer;
}

.arrow{
    color:white;
    font-size: 2rem;
}

.text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 4rem;
    font-family: 'Signika Negative',sans-serif;
    color: white;
}

.sub_container{
    height: 90dvh;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    border-radius:2rem 2rem 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.terms_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2rem;
    width: 100%;
}
.heading_text{
    width: 100%;
}
.heading_text h3{
    color: #333;
    font-size: 3rem;
    font-weight: bolder;
    font-family: 'Signika Negative',sans-serif;
    text-align: center;
}
.terms_subContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 2rem 10rem 2rem 10rem;
    gap: 2rem;
    flex-direction: column;
}

.term{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.8rem;
}

.term h3{
    font-size: 2.5rem;
    color: #333;
    font-family: 'Signika Negative',sans-serif;
}
.term p{
    font-size: 1.5rem;
    color: #333;
    font-family: 'Signika Negative',sans-serif;
}

@media screen and (max-width:650px){
    .terms_subContainer{
        padding: 2rem 2rem 2rem 2rem;
    }
    
}