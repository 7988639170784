.text_container{
    position: relative;
    width: 100%;
}

.back_btn{
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translate(0,-50%);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    padding: 1rem;
    background: #407bff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
.back_btn:hover{
    background: #002473;
}

.back_icon{
    font-size: 2rem;
    font-weight: bolder;
    color: white;
}

.text{
    font-size: 3rem;
    font-family: "Single Day",sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.3rem;
}