.option_box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1rem;
    padding: 1rem;
    background-color: #407bff;
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
    border-radius: 1rem;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out; /* Add transition for a smoother effect */
}

.option_box:hover{
    /* Apply an inside box shadow on hover */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); /* Add the inside box shadow on hover */
}

.correct_option_box{
    background-color: #4BAD50;
}

.wrong_option_box{
    background-color: #e78c6d;
}

.icon{
    font-size: 2rem;
    color:rgb(128, 0, 0);
}