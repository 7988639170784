/* HEADER SECTION STYLES BOTH DESKTOP AND MOBILE */

.header_section{
    background-color:#008ADD;
    width: 100%;
    padding:3rem 10rem 5rem 10rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10rem;
    flex-direction: column;
}
.navbar{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo{
    width:25rem;
    overflow: hidden;
}
.logo_img{
    width:100%;
    object-fit:cover;
}

.menu_list{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    z-index: 99;
}

.menu{
    font-size: 2rem;
    color:#ececec;
    font-family: 'Signika Negative',sans-serif;
    cursor: pointer;
}

.menu_icon{
    display: none;
    visibility: hidden;
}

.container_section{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    place-items: center;
    gap: 4rem;
}

.container_img{
    width: 20rem;
    height:40rem;
    overflow: hidden;
}
.container_img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.container_intro{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.title{
    font-size: 5rem;
    color:white;
    font-family: 'Signika Negative',sans-serif;
    font-weight: 800;
}
.paragraph{
    font-size: 2.5rem;
    color: white;
    font-family: 'Signika Negative',sans-serif;
    font-weight: 500;
}

.buttons_container{
    margin-top: 1rem;
    width:100%;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 2rem;
}

.play_btn{
    padding: 1rem 2rem 1rem 2rem;
    width: 15rem;
    background-color: #001441;
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    font-weight: 400;
    border-radius: 0.5rem;
    color: white;
    border: 0.1rem solid #001441;
    transition: all 0.3s;
}

.play_btn.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

.play_btn:hover{
    background-color: #000c27;
    border:0.1rem solid #000c27;
}

.play_btn:active{
    transform: scale(1.1);
}

.demo_btn{
    padding: 1rem 2rem 1rem 2rem;
    width: 17rem;
    background:#ffa500;
    border:0.1rem solid #ffa500;
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    font-weight: 400;
    border-radius: 0.5rem;
    color: #000c27;
    transition: all 0.3s;
}

.demo_btn:hover{
    background-color: #ffa500;
    border: 0.1rem solid #ffa500;
    color: #001441;
}

.demo_btn:active{
    transform: scale(1.1);
}

@media screen and (max-width:1000px){
    .header_section{
        padding:5rem 2rem 5rem 2rem;
    }
}

@media screen and (max-width:840px){
    .header_section{
        gap: 3rem;
    }

    .menu_list{
        display: none;
        visibility: hidden;
    }

    .menu_icon{
        display: block;
        visibility: visible;
    }
    .icon{
        cursor: pointer;
        font-size: 3rem;
        color: white;
        transition: all 0.3s;
    }
    .icon:active{
        transform: scale(1.1);
    }

    .container_section{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 5rem;
    }
    .container_img{
        order: 1;
    }
}

@media screen and (max-width:600px){
    .header_section{
        padding: 40px 20px;
    }
    .logo{
        /* width:15rem; */
        width:19rem;
    }
    .container_intro{
        gap: 2rem;
    }
    .title{
        font-size: 3rem;
        line-height: 3rem;
    }
    .paragraph{
        font-size: 1.5rem;
    }
}

/* HOW TO PLAY SECTION STYLES BOTH DESKTOP AND MOBILE */

.how_to_play_section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:4rem;
    padding:5rem 10rem 5rem 10rem;
    /* background-color: #008ADD; */
    width:100%;
}

.how_to_play_title{
    width: 100%;
}
.how_to_play_title h3{
    text-align: center;
    font-size: 5rem;
    color: #333;
    font-family: 'Signika Negative',sans-serif;
    font-weight: 800;
}

.card_grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
    width:100%;
}

.card_play_1,.card_play_2,.card_play_3{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* height: max-content; */
    /* border: 0.1rem solid rgba(0,0,0,0.125); */
    width: 100%;
    gap: 2rem;
    border-radius: 0.5rem;
    padding: 4rem 2rem 4rem 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #f0f8ff;
}

.number_image_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.number_image{
    width: 15rem;
    height: 8rem;
    object-fit: contain;
}

.play_description p{
    font-size: 2rem;
    /* color: #fff; */
    color: #333;
    font-family: 'Signika Negative',sans-serif;
    font-weight: 400;
    text-align: center;
}

@media screen and (max-width:1000px){
    .how_to_play_section{
        padding:40px 20px;
    }
}

@media screen and (max-width:900px){
    .card_grid{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:600px){
    .card_grid{
        grid-template-columns: 1fr;
    }
    .how_to_play_title h3{
        font-size: 3rem;
    }
    .play_description p{
        font-size: 1.5rem;
    }
}

/* TERMS AND CONDITIONS SECTION STYLE BOTH DESKTOP AND MOBILE */

.terms_conditions_section{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    padding:5rem 10rem 5rem 10rem;
    background-color: #008ADD;
}

.terms_title{
    width: 100%;
}
.terms_title h3{
    text-align: center;
    font-size: 5rem;
    color: #fff;
    font-family: 'Signika Negative',sans-serif;
    font-weight: 800;
}

.terms_paragraph{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
}

.terms_paragraph_sub_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
}

.term_paragraph{
    font-size: 1.8rem;
    /* color: #333; */
    color: #fff;
    font-family: 'Signika Negative',sans-serif;
    text-align: left;
}

.circle{
    margin-top: 0.8rem;
    font-size: 1rem;
    /* color: #000c27; */
    color: #ffffff;
}

.terms_button_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.terms_button{
    padding: 1rem 2rem 1rem 2rem;
    width: 15rem;
    background-color: #ffa500;
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    font-weight: 400;
    border-radius: 0.5rem;
    color: #001441;
    border: 0.1rem solid #ffa500;
    transition: all 0.3s;
}

/* .terms_button:hover{
    background-color: #001441;
    border:0.1rem solid #001441;
    color: #fff;
} */

.terms_button:active{
    transform: scale(1.1);
}

@media screen and (max-width:1000px){
    .terms_conditions_section{
        padding:1rem 2rem 1rem 2rem;
    }
}

@media screen and (max-width:600px){
    .terms_title h3{
        font-size: 3rem;
    }
    .term_paragraph{
        font-size: 1.5rem;
    }
}

/* LEADERBOARD SECTION */

.leaderboard_container{
    width:100%;
    position: relative;
    overflow: hidden;
}
.leaderAnimation_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.leaderboardAnimation{
    width: 100%;
    height: 100%;
}

.leaderboard_positions_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.leaderboard_top3_positions{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width:100%;
}
.position1_animation_container,.position2_animation_container,.position3_animation_container{
    width: 10rem;
    height: 10rem;
}
.award1Animation,.award2Animation,.award3Animation{
    width: 100%;
    height: 100%;
}
.position2_animation_container,.position3_animation_container{
    margin-top: 4rem;
}

.player_info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 0.3rem;
    width: 100%;
}
.player_text{
    width: 100%;
    font-size: 1.4rem;
    font-family: 'Signika Negative',sans-serif;
    font-weight: bold;
}

.leaderboard_table_container{
    width: 100%;
}
.leaderboard_table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
}
.leaderboard_table_header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30rem;
    background-color: #008ADD;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.5);
}
.leaderboard_column1,.leaderboard_column2,.leaderboard_column3{
    padding: 1rem;
    width: 10rem;
}
.column_name{
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
    text-align: center;
    color: #fff;
}

.leaderboard_table_data{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30rem;
    background-color: #E2E8F0;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.5);
}
.leaderboard_row1,.leaderboard_row2,.leaderboard_row3{
    padding: 1rem;
    width: 10rem;
}
.row_name{
    font-size: 1.6rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
    text-align: center;
    color: #0f1010;
}
@media screen and (max-width:600px){
    .player_text{
        font-size: 1.2rem;
    }
    .player_info{
        text-align: center;
    }
    .leaderboard_positions_container{
        gap: 3rem;
    }
}

.leaderboard_container_2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.leaderboard_container_2 h3{
    font-family: 'Signika Negative',sans-serif;
     color: #333;
     font-size: 3rem;
}