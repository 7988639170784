.container{
    width: 100%;
    padding: 3rem 1rem 3rem 1rem;
    position: relative;
    background-color: #ecf2ff;
}

.sub_container{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 0 0.3rem 3rem rgba(32, 61,127, 0.3);
    overflow: hidden;
}

.timeText{
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Single Day',sans-serif;
    color: black;
}

.timerAnimation{
    height: 5rem;
    object-fit: cover;
}

.text_container{
    position: relative;
    width: 100%;
}
.back_btn{
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translate(0,-50%);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    padding: 1rem;
    background: #407bff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
.back_btn:hover{
    background: #002473;
}
.back_icon{
    font-size: 2rem;
    font-weight: bolder;
    color: white;
}
.text{
    font-size: 3rem;
    font-family: "Single Day",sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.3rem;
}

.sub_container_sub{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    padding: 2rem 1rem 2rem 1rem;
}

.quizAnimationContainer{
    justify-self: center;
    align-self: center;
}

.quizAnimation{
    height: 30rem;
}

.questions_options_container{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem 1rem 0;
    border: 3px solid #407bff;
    border-radius: 3rem;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.4);
}

.questionMarkContainer{
    position:absolute;
    top: -10%;
    left: 50%;
    transform: translate(-50%, -10%);
}

.questionMarkAnimation{
    height: 10rem;
}

.questions_options_sub_container{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position:relative;
}

.question_box{
    width: 100%;
}

.question_text{
    text-align: center;
    color: #407bff;
    font-size: 4rem;
    z-index: 99;
    letter-spacing: 0.1rem;
    font-weight: bold;
    font-family: 'Single Day',sans-serif;
}

.options_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.option_box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1rem;
    padding: 1rem;
    background-color: #407bff;
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
    border-radius: 1rem;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out; /* Add transition for a smoother effect */
}

.option_box:hover{
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); /* Add the inside box shadow on hover */
}

.scoreContainer{
    width: 100%;
}

.score{
    font-size: 2rem;
    font-family: 'Rubik Bubbles',sans-serif;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width:900px){
    .sub_container_sub{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 2rem 1rem 2rem 1rem;
    }
    .quizAnimationContainer{
     display: none;
     visibility: hidden;
    }
}

@media screen and (max-width:480px){
    .container{
        padding: 3rem 0.5rem 3rem 0.5rem;
    }
    .questions_options_sub_container{
        width: 95%;
    }
    
    .question_box{
        margin-top: 2rem;
    }
    
    .question_text{
        color: #407bff;
        font-size: 2rem;
    }
}

.overlapContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 499;
}

.overlapAnimationContainer{
    justify-self: center;
    align-self: center;
}

.overlapAnimation{
    height: 30rem;
}

.skip_button{
    position: absolute;
    top: 0;
    right: 2rem;
    transform: translate(0,-2rem);
    padding: 0;
}

.skip_button button {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    user-select: none;
    touch-action: manipulation;
   }
   
   .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform
       600ms
       cubic-bezier(.3, .7, .4, 1);
   }
   
   .edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
       to left,
       hsl(340deg 100% 16%) 0%,
       hsl(340deg 100% 32%) 8%,
       hsl(340deg 100% 32%) 92%,
       hsl(340deg 100% 16%) 100%
     );
   }
   
   .front {
    font-family: 'Oswald',sans-serif;
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 1.1rem;
    color: white;
    background: hsl(345deg 100% 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition: transform
       600ms
       cubic-bezier(.3, .7, .4, 1);
   }
   
   .skip_button button:hover {
    filter: brightness(110%);
   }
   
   .skip_button button:hover .front {
    transform: translateY(-6px);
    transition: transform
       250ms
       cubic-bezier(.3, .7, .4, 1.5);
   }
   
   .skip_button button:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
   }
   
   .skip_button button:hover .shadow {
    transform: translateY(4px);
    transition: transform
       250ms
       cubic-bezier(.3, .7, .4, 1.5);
   }
   
   .skip_button button:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
   }
   
   .skip_button button:focus:not(:focus-visible) {
    outline: none;
   }




/* Dynamic option box styling */

.icon{
    font-size: 2rem;
    color:rgb(128, 0, 0);
   }

   .wrong_option_box{
    background-color: #e78c6d;
   }

   .correct_option_box{
    background-color: #4BAD50;

   }