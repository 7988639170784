.container{
    position:relative;
    width:100%;
    height:100dvh;
    background-attachment: fixed;
    background-color: #008ADD;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:1rem;
}

.back_icon_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    padding: 1rem 4rem 1rem 4rem;
    position: relative;
}

.back_icon{
    width: 4rem;
    height: 4rem;
    background-color: #1F1C3A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.3rem 2rem rgba(0,0,0,0.4);
    cursor: pointer;
}

.arrow{
    color:white;
    font-size: 2rem;
}

.text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 4rem;
    font-family: 'Signika Negative',sans-serif;
    color: white;
}

.sub_container{
    height: 90dvh;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    border-radius:2rem 2rem 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2rem 3rem 2rem 3rem;
}

.heading{
    font-size: 3rem;
    font-family: 'Signika Negative',sans-serif;
    color: #333;
    font-weight: bolder;
}

.account_container{
    width:100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.avatar_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.avatar{
    height:15rem;
    width:15rem;
    object-fit: contain;
}

.user_text{
    font-size: 2.5rem;
    color: #333;
    font-family: 'Signika Negative',sans-serif;
}

.score_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 30rem;
    gap:1rem;
    padding: 0 1rem 0 1rem;
}

.score_text{
    font-size: 2rem;
    color: #333;
    font-family: 'Signika Negative',sans-serif;
}

.score_sub_container{
    width: 100%;
    padding: 0.5rem 0 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    background-color:#cbc8d1;
    border-radius: 0.2rem;
}

.score_image{
    width: 6rem;
    height: 4rem;
    object-fit: contain;
}

.score{
    font-size: 2rem;
    color: #333;
    font-family: 'Signika Negative',sans-serif;
}