.timerAnimation{
    height: 5rem;
    object-fit: cover;
}

.timeText{
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Single Day',sans-serif;
    color: black;
}