.modal_position{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 60%;
    height: 45rem;
    z-index:500;
    box-shadow: 0 1rem 2rem rgba(0,0,0,1);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    gap: 1rem;
    background: url("../StaticImages/modalimage2.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s, visibility 0.4s, transform 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.modal_position.modal_show{
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
}

.text_container{
    width:100%;
}
.text_container h2{
    font-size: 5rem;
    font-family: 'Single Day',sans-serif;
    text-align: center;
    font-weight: 800;
    transform: skewY(-5deg);
    color:rgb(0, 65, 95);
}

.box{
    padding: 1rem;
    /* border:2px solid #38B6FF; */
    border:2px solid #ff7259cc;
    z-index: 600;
    width: 60%;
     transform:skewY(-5deg); 
    border-radius: 2rem;
    box-shadow: 1rem 1rem #ff7259cc;
    background-color:#f5d7d3;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.underline{
    width: 100%;
    height: 3rem;
    border-bottom: 1px solid #ff7259cc;
}

.icons_container{
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:1rem;
    margin-left: 1rem;
}

.box_question{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.question{
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Single Day',sans-serif;
    text-align: center;
    font-weight: 600;
    color:rgb(0, 65, 95);
}

.input_box{
    border-radius: 4rem;
    border: 1px solid #f3980f;
    overflow: hidden;
    background-color: #f3980f;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.input_box input{
    background-color: #f3980f;
    width: 80%;
    color:rgb(0, 65, 95);
    font-family: 'Single Day',sans-serif;
    font-size: 2rem;
    outline: none;
    border-bottom: 4px dotted rgb(253, 72, 0);
}

.input_box input::placeholder{
    color:rgb(0, 65, 95);
    font-size: 2rem;
    font-family: 'Single Day',sans-serif;
}

.btn_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn{
    border-radius: 2rem;
    width: 15rem;
    background-color: rgb(0, 6, 95);
    color: #38B6FF;
    padding: 1rem;
    font-size: 2rem;
    box-shadow: 0 1rem 2rem rgba(0,0,0,1);
    text-transform: uppercase;
    font-family: "Single Day",sans-serif;
    transition:transform 0.3s ease-in-out;
}

.btn:hover{
    transform: scale(1.1);
}

@media screen and (max-width:1100px){
    .box{
        width: 80%;
    }
}

@media screen and (max-width:800px){
    .text_container h2{
        font-size: 3rem;
    }
    .box_question{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    
    .question{
        font-size: 2rem;
    }
    
    .input_box{
        padding: 0.6rem;
        width: 95%;
    }
    
    .input_box input{
        font-size: 1.5rem;
    }
    
    .input_box input::placeholder{
        font-size: 1.5rem;
    }
}

@media screen and (max-width:800px){
    .modal_position{
        width: 80%;
    }
}