.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.sub_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.3rem;
    flex-wrap: wrap;
    max-width: 700px;
}

.circle_container{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}

.circle_with_line{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.2rem;
}

.circle_with_line .circle{
    position:relative;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    /* background: #407bff; */
    background-color: #407cff7e;
}
.circle_with_line .line{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #407cff7e;
    width:3rem;
    height: 0.2rem;
}

h2{
    margin-right: 3.5rem;
    font-family: 'Single Day',sans-serif;
    font-size: 2rem;
    font-weight: bold;
}


.circle_with_line_active{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.2rem;
}

.circle_with_line_active .circle{
    position:relative;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    /* background: #407bff; */
    background-color: #407cff;
}
.circle_with_line_active .line{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #407cff7e;
    width:3rem;
    height: 0.2rem;
}

.line_active{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #407cff;
    width:3rem;
    height: 0.2rem;
}

.icon{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: white;
    font-weight: bolder;
    font-size: 2rem;
}
