/* Animation Containers */

.container{
    width: 100%;
    padding: 3rem 1rem 3rem 1rem;
    position: relative;
    background-color: #ecf2ff;
}

.sub_container{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 0 0.3rem 3rem rgba(32, 61,127, 0.3);
    overflow: hidden;
}

/*  */

.questions_options_container{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem 1rem 0;
    border: 3px solid #407bff;
    border-radius: 3rem;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.4);
}

.questions_options_sub_container{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position:relative;
}

.options_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

@media screen and (max-width:480px){
    .container{
        padding: 3rem 0.5rem 3rem 0.5rem;
    }
    .questions_options_sub_container{
        width: 95%;
    }
}

.overlapContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 499;
}

.overlapAnimationContainer{
    justify-self: center;
    align-self: center;
}

.overlapAnimation{
    height: 30rem;
}

.skip_button{
    position: absolute;
    top: 0;
    right: 2rem;
    transform: translate(0,-2rem);
    padding: 0;
}