.container{
    position:relative;
    width:100%;
    height:100dvh;
    background-attachment: fixed;
    background-color: #008ADD;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:1rem;
}

.back_icon_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    padding: 1rem 4rem 1rem 4rem;
    position: relative;
}

.back_icon{
    width: 4rem;
    height: 4rem;
    background-color: #1F1C3A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.3rem 2rem rgba(0,0,0,0.4);
    cursor: pointer;
}

.arrow{
    color:white;
    font-size: 2rem;
}

.text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 4rem;
    font-family: 'Signika Negative',sans-serif;
    color: white;
}

.sub_container{
    width: 100%;
    height: 90dvh;
    z-index: 99;
    background-color: #fff;
    border-radius:2rem 2rem 0 0;
    box-shadow: 0 0.3rem 3rem rgba(32, 61,127, 0.3);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}


.game_levels_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
}

.game_levels_subcontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 40rem;
    margin-top: 3rem;
}

.game_level_easy,.game_level_medium,.game_level_hard{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:1rem;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem 1rem 2rem;
    background-color: #1F1C3A;
    border-radius: 2rem;
    cursor: pointer;
}

.game_level_practice{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:1rem;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 2rem 1rem 2rem;
    background-color: #1F1C3A;
    border-radius: 2rem;
    cursor: pointer;
}

.practice_text{
    padding: 1rem 2rem 1rem 2rem;
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.easy_text,.medium_text,.hard_text{
    padding: 1rem 2rem 1rem 2rem;
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:600px) {
    .game_levels_subcontainer{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 90%;
    }
    .back_icon_container{
        padding: 1rem 1rem 1rem 1rem;
    }
}