.container{
    background-color: #ecf2ff;
    width: 100%;
    height:max-content;
    /* height: 100dvh; */
    background-attachment: fixed;
}

.sub_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.animation_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:20rem;
}

.loseAnimation{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.points_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.points_sub_container{
    /* background-color: black; */
    background-color: #001441;
    width: max-content;
    height: 5rem;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-radius: 0.4rem;
    overflow: hidden;
}

.point_image{
    width: 3rem;
    height: 3rem;
    object-fit: contain;
}

.point_text{
    font-size: 2rem;
    color:white;
    font-family: 'Signika Negative',sans-serif;
    letter-spacing: 0.1rem;
}



.text_container{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width:100%;
}

.text{
    font-size:5rem;
    font-weight: 800;
    font-family: "Single Day",sans-serif;
}

.paragraph{
    font-size: 2rem;
    font-family: "Single Day",sans-serif;
}

.btn_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.back_btn button {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    user-select: none;
    touch-action: manipulation;
   }
   
   .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform
       600ms
       cubic-bezier(.3, .7, .4, 1);
   }
   
   .edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
       to left,
       hsl(340deg 100% 16%) 0%,
       hsl(340deg 100% 32%) 8%,
       hsl(340deg 100% 32%) 92%,
       hsl(340deg 100% 16%) 100%
     );
   }
   
   .front {
    font-family: 'Lato',sans-serif;
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 1.3rem;
    color: white;
    background: hsl(345deg 100% 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition: transform
       600ms
       cubic-bezier(.3, .7, .4, 1);
   }
   
   .back_btn button:hover {
    filter: brightness(110%);
   }
   
   .back_btn button:hover .front {
    transform: translateY(-6px);
    transition: transform
       250ms
       cubic-bezier(.3, .7, .4, 1.5);
   }
   
   .back_btn button:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
   }
   
   .back_btn button:hover .shadow {
    transform: translateY(4px);
    transition: transform
       250ms
       cubic-bezier(.3, .7, .4, 1.5);
   }
   
   .back_btn button:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
   }
   
   .back_btn button:focus:not(:focus-visible) {
    outline: none;
   }

   .icon{
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0 0.7rem 0 0;
   }

   .play_btn button {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    user-select: none;
    touch-action: manipulation;
   }
   
   .play_btn button:hover {
    filter: brightness(110%);
   }
   
   .play_btn button:hover .front {
    transform: translateY(-6px);
    transition: transform
       250ms
       cubic-bezier(.3, .7, .4, 1.5);
   }
   
   .play_btn button:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
   }
   
   .play_btn button:hover .shadow {
    transform: translateY(4px);
    transition: transform
       250ms
       cubic-bezier(.3, .7, .4, 1.5);
   }
   
   .play_btn button:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
   }
   
   .play_btn button:focus:not(:focus-visible) {
    outline: none;
   }


.game_history_container{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game_history_sub_container{
    background-color:#021520;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    box-shadow: 0 0.4rem 2rem rgba(0,0,0,0.5);
}

.card_correct,.card_wrong{
    padding: 2rem 2rem 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000e2eda;
    border-radius: 0.4rem;
    box-shadow: 0 0.4rem 2rem rgba(0,0,0,0.5);
}

.card_text{
    font-size: 2rem;
    color: white;
    font-family: 'Signika Negative',sans-serif;
}

.card_paragraph{
    font-size: 2rem;
    color: white;
    font-family: 'Signika Negative',sans-serif;
}