.container{
    width: 100%;
    padding: 3rem 1rem 3rem 1rem;
    position: relative;
    background-color: #ecf2ff;
    height:100dvh;
    background-attachment: fixed;
}

.sub_container{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 0 0.3rem 3rem rgba(32, 61,127, 0.3);
    overflow: hidden;
}

@media screen and (max-width:480px){
    .container{
        padding: 3rem 0.5rem 3rem 0.5rem;
    }
}