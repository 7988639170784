.quizAnimationContainer{
    justify-self: center;
    align-self: center;
}

.quizAnimation{
    height: 30rem;
}

@media screen and (max-width:900px){
    .quizAnimationContainer{
     display: none;
     visibility: hidden;
    }
}