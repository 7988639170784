.question_box{
    width: 100%;
}

.question_text{
    text-align: center;
    color: #407bff;
    font-size: 4rem;
    z-index: 99;
    letter-spacing: 0.1rem;
    font-weight: bold;
    font-family: 'Single Day',sans-serif;
}


@media screen and (max-width:480px){
    .question_box{
        margin-top: 2rem;
    }
    .question_text{
        color: #407bff;
        font-size: 2rem;
    }
}
