.sub_container_sub{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    padding: 2rem 1rem 2rem 1rem;
}

@media screen and (max-width:900px){
    .sub_container_sub{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 2rem 1rem 2rem 1rem;
    }
}