.questionMarkContainer{
    position:absolute;
    top: -10%;
    left: 50%;
    transform: translate(-50%, -10%);
}

.questionMarkAnimation{
    height: 10rem;
}
