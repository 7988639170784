/* .drawer{
    position: fixed;
    top: 0;
    right: 0;
    width: 30rem;
    height: 100dvh;
    background-color:#000C27;
} */

.drawer{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100dvh;
    background-color:#000c27a8;
}

/* .container{
    position: relative;
    height: 100dvh;
    width: 30rem;
} */

.container{
    position: fixed;
    top: 0;
    right: 0;
    height: 100dvh;
    width: 30rem;
    background-color:#000C27;
}

.menu_list{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-30%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3rem;
    padding: 0 1rem 0 1rem;
    width: 100%;
}

.menu{
    font-size: 2rem;
    color:#ececec;
    font-family: 'Signika Negative',sans-serif;
}

.close_icon{
    position:absolute;
    top: 5%;
    right: 5%;
    transform: translate(-5%,-5%);
}
.icon{
    font-size: 2rem;
    cursor: pointer;
    color: white;
}

@media screen and (max-width:500px) {
    /* .drawer{
        width: 25rem;
    } */
    .drawer{
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100dvh;
        background-color:#000c27a8;
    }
    .container{
        width: 25rem;
    }
}